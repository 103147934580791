@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--opacity-marks: 0.5;
}

.bg-blue {
	background-color: rgb(0, 0, 255);
	border: none;
}

.bg-yellow {
	background-color: rgb(255, 255, 0);
	border: none;
}

.bg-red {
	background-color: rgb(255, 0, 0);
	border: none;
}

.bg-green {
	background-color: rgb(0, 255, 0);
	border: none;
}

.bg-mark-blue {
	/* background-color: #1e90ff; */
	background-color: rgba(0, 0, 255, var(--opacity-marks));
	opacity: var(--opacity-marks);
}

.bg-mark-yellow {
	/* background-color: #ffe75d; */
	background-color: rgba(255, 255, 0, var(--opacity-marks));
	opacity: var(--opacity-marks);
}

.bg-mark-red {
	/* background-color: #ff4b4b; */
	background-color: rgba(255, 0, 0, var(--opacity-marks));
	opacity: var(--opacity-marks);
}

.bg-mark-green {
	/* background-color: #006600; */
	background-color: rgba(0, 255, 0, var(--opacity-marks));
	opacity: var(--opacity-marks);
}